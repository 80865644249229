import * as React from "react"
import Layout from "../components/layout"
import Gallery from "../components/gallery"
import EventInfo from "../components/eventInfo"
import EventRelated from "../components/eventRelated"
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const EventPage = ({data}) => {
  var related =  data.related.edges;
  related = related.sort(() => Math.random() - 0.5).slice(0,2);
  return (
  <Layout>
    <div className='flex m-wrap'>
      <div className='w-50 m-100'>
        <EventInfo data={data} />
      </div>
      <div className='w-50 m-100'>
        <Gallery images={data.event.gallery} />
      </div>
    </div>
    <EventRelated  related={related} />
    <Spacer />
  </Layout>
  )
} 


export const Head = ({data}) => <Seo title={data.event.name} />

export default EventPage


export const query = graphql`
  query EventQuery($id: String!) {
    event:datoCmsEvent(id: { eq: $id }) {
      name
      introduction
      gallery {
        gatsbyImageData
      }
      date:meta {
        publishedAt(formatString: "D MMMM YYYY")
      }
      link
      linkText
      linkTitle
    }
    related:allDatoCmsEvent(filter: {id: {ne: $id}}) {
      edges {
        node {
          slug
          name
          gallery {
            gatsbyImageData
          }
        }
      }
    }
  }
`
