import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"

const EventRelated = ({related}) => {
  return (
  <div className='other--events w-50 m-100 mt40'>
    <p className='m0 pl20'>Related News</p>
    <div className='p20 flex w-50 m-66'>
      {related?.map((event, index) => {
        return ( 
          <Link to={'/news/'+event.node.slug} className='w-50 pr10 link' key={index}>
            <div className='ratio-1-2 bg-grey pos-rel mb5'>
              <GatsbyImage className='bg-image' image={event.node.gallery[0]?.gatsbyImageData} alt='Event Thumbnail' />
            </div>
            <p className='aime uppercase m0'>{event.node.name}</p>
          </Link>
        )
      })}
    </div>
  </div>
  )
}

export default EventRelated
