import React, { useEffect } from 'react';
const StoreInfo = ({data}) => {
  var event = data.event
  
  useEffect(() => {
    setInterval(function () {
      if(window.pageYOffset > 200) {
        document.querySelector('.business--details')?.classList.add('active')
      } else {
        document.querySelector('.business--details')?.classList.remove('active')
      }
    }, 300);
  },[])

  return (
  <div className='store--information sticky'>
    <div className='mh-100vh m-mha flex flex-wrap'>
      <div className='w-100 p20'>
        <div className='w-70 mt40 m-100'>
          <p className='m0 uppercase text-center  small'>{event.date.publishedAt}</p>
          <p className='m0 h1 mt20 text-center '>{event.name}</p>
          <div className='white-space mt20' dangerouslySetInnerHTML={{ __html: event.introduction }} />
        </div>
      </div>
      <div className='mta m-100 p20 text-right m-text-left w-30 mla mr20 f-small business--details'>
        {event.linkTitle}<br/>
        <a className='ul-link' target='_blank' rel='noreferrer' href={event.link}>{event.linkText}</a>
      </div>
    </div>
  </div>
  )
}

export default StoreInfo
