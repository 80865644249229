import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

const Gallery = ({images, margin, image}) => {
  return (
  <div className='gallery pr20 m-pl20'>
    {( images ?
    <> 
      {images?.map((image, index) => {
        return ( 
          <div className={'pos-rel '+(margin!==false ? 'mb10':'')} key={index}>
            <GatsbyImage key={index} className='image w-100' image={image?.gatsbyImageData} alt='Gallery Thumbnail' />
          </div>
        )
      })}
    </> :
    <div className='mh-100ap m-mha m-ratio-2-1 bg-grey pos-rel'>
      <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt='Gallery Thumbnail' />
    </div> )}
  </div>
  )
}

export default Gallery
